import React, { useState, useEffect,useCallback } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchGet, fetchPost } from "../request";
import './parknow.css'
//tmpcmt
export function ParkNow(props) {
    const [parkingLot, setParkingLot] = useState("Morewood test 1");
    const [dateInput, setDateInput] = useState(new Date(Date.now()));
    const [timeInput, setTimeInput] = useState("08:00");
    const [timeOptions, setTimeOptions] = useState([]);
    const [avaiDict, setAvaiDict] = useState({});
    const [optionDidUpdate, setOptionDidUpdate] = useState(false);
    const [durationPrompt, setDurationPrompt] = useState(<label></label>);
    const [duration, setDuration] = useState(15);
    const [mx, setMx] = useState(0); // the max duration possible
    const [bookingPrompt, setBookingPrompt] = useState();
    const [price, setPrice] = useState(0)
    const [vehicle, setVehicle] = useState('My Car 1')
    const [spot, setSpot] = useState()
    const [spotOption, setspotOption] = useState([]);
    const [payment, setPayment] = useState('Balance')
    const [isFormComplete, setIsFormComplete] = useState(false);
    const [spotsMaxAvailableTime, setspotsMaxAvailableTime] = useState({});
    const [spotTimeOptions, setSpotTimeOptions] = useState([]);
    const [getOutTimeStamp, setGetOutTimeStamp] = useState("00000.0");
    const [isBookingSuccess, setIsBookingSuccess] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const startHour = process.env.REACT_APP_START_HOUR;
    const endHour = process.env.REACT_APP_END_HOUR;



    const history = useHistory();

    const handleShow = () => {  // clear the previous state and display the modal
        setDuration(0);
        setBookingPrompt(<p></p>);
        setDurationPrompt(<label>Choose a duration:</label>);
        setOptionDidUpdate(false);
    }

    const handleClose = () => {
        props.handleClose();
    }

    const checkFormComplete =  useCallback(() => {
    if (parkingLot && duration > 0 && timeInput && vehicle && payment && spot != null) {
        setIsFormComplete(true);
    } else {
        setIsFormComplete(false);
        }
    },[parkingLot,duration,timeInput,vehicle,payment,spot]);

    useEffect(() => {
        checkFormComplete();
    }, [parkingLot, duration, timeInput, vehicle, payment, spot,checkFormComplete]);



    const handleDateChange = (event) => {
        let date = event.target.value.split("-"); // yyyy-mm-dd
        setDateInput(new Date(date[0], date[1] - 1, date[2]));
        setOptionDidUpdate(false);
    }

    const handleTimeChange = (event) => {
        setTimeInput(event.target.value);
        setDuration(0);
        setDurationPrompt(<label>Choose a duration:</label>);
        setMx(avaiDict[event.target.value]);
    }
    const calculateDuration = (event) => {
        if( event.target.value == "NULL"){
            setDurationPrompt(<label>Set duration</label>);
            alert("Select a valid duration")
        }
        else {
            // Get the current timestamp in seconds
            const startTime = Date.now() / 1000;
        
            // Get the end time from the event and convert it to seconds
            const endTime = parseFloat(event.target.value);
        
            // Calculate the difference in seconds
            const durationInSeconds = endTime - startTime;
        
            // Convert the duration to minutes
            let durationInMinutes = Math.floor(durationInSeconds / 60);
            durationInMinutes = Math.ceil(durationInMinutes / 15) * 15;
            // Update the state with the calculated duration
            let charge = durationInMinutes / 60 * price;
            let fee = props.walkIn ? 0 : 3;
            setDuration(durationInMinutes);
            if (durationInMinutes === '0' || durationInMinutes === 0) {
                setDurationPrompt(<label>Select valid duration</label>);
            } else {
                setDurationPrompt(
                    <label>
                        <p> You chose <strong>{durationInMinutes} mins</strong>: </p>
                        {/* <p> Hourly Rate: <strong>${price}/hr * {durationInMinutes / 60}hr(s) = {charge}</strong> </p>
                        {!props.walkIn && <p>Reservation Fee: <strong>$3</strong> (<u>non-refundable</u>)</p>}
                        <p> Total: <strong>${charge + fee}</strong> </p> */}
                    </label>
                );
            }
        }
        
    }


    const handleSliderChange = (event) => {
        let duration = event.target.value;
        let charge = duration / 60 * price;
        let fee = props.walkIn ? 0 : 2;
        setDuration(duration);

        if (duration === '0' || duration === 0) {
            setDurationPrompt(<label>Choose a duration:</label>);
        } else {
            setDurationPrompt(
                <label>
                    <p> You chose <strong>{duration} mins</strong>: </p>
                    <p> Hourly Rate: <strong>${price}/hr * {duration / 60}hr(s) = {charge}</strong> </p>
                    {!props.walkIn && <p>Reservation Fee: <strong>$2</strong> (<u>non-refundable</u>)</p>}
                    <p> Total: <strong>${charge + fee}</strong> </p>
                </label>
            );
        }
    }

    const getInTime = () => {   // utility function to get the chosen check-in time
        let checkInTime;
        if (!props.walkIn) {    // if in reservation mode
            let inTime = timeInput.split(":");
            checkInTime = new Date(dateInput);
            checkInTime.setHours(inTime[0]);
            checkInTime.setMinutes(inTime[1]);
        } else {    // if in walk-in mode
            let now = new Date(Date.now());
            let minute = Math.floor(now.getMinutes() / 15) * 15;
            checkInTime = new Date(now);
            checkInTime.setMinutes(minute);
        }
        return checkInTime;
    }
    const updateCallback = (data) => {
        // Assuming data contains an array of vehicles
        if ('plate' in data) {
            setVehicle([data['plate']]);
        }
    }
    const fetchUserInfo = () => {
        fetchGet('/api/account/profile', {}, updateCallback)
    }
    const fetchSpots = () => { // fetch the information of the chosen parking lot
        const formatNum = (num) => num.toString().padStart(2, '0');

        const minutesToHHMM = (minutes) => {
            const hours = Math.floor(minutes / 60);
            let mins = minutes % 60;
            mins = Math.floor(mins / 15) * 15; // rounding up to lower 15 ( it is a designe logic so no one can book slot for last 15 min)
            return `${formatNum(hours)}Hr:${formatNum(mins)}Min`;
        };
        // const generateTimeSlots = (startTime, endTime) => {
        //     const slots = [];
        //     const interval = 15 * 60 * 1000; // 15 minutes in milliseconds
        
        //     // Calculate the next 15-minute interval from the start time
        //     //startTime += 15 * 60 * 1000; // Add 15 minutes
        //     let currentTime = startTime;
        //     const minutes = new Date(currentTime).getMinutes();
        //     const nextQuarterHour = Math.ceil(minutes / 15) * 15;
        //     currentTime = new Date(currentTime).setMinutes(nextQuarterHour, 0, 0);
        
        //     while (currentTime <= endTime) {
        //         let dt = new Date(currentTime);
        //         let timeString = `${formatNum(dt.getHours())}:${formatNum(dt.getMinutes())}`;
        //         slots.push({ key: timeString, value: currentTime / 1000 }); // Store time as seconds since epoch
        //         currentTime += interval;
        //     }
        
        //     return slots;
        // };

        const generateTimeSlots = (startTime, endTime) => {
            const slots = [];
            const interval = 15 * 60 * 1000; // 15 minutes in milliseconds
        
            const maxEndTime = new Date(endTime);
            maxEndTime.setHours(process.env.REACT_APP_END_HOUR, 0, 0, 0); // Set max end time based on environment variable
            console.log('The end time is')
            console.log(maxEndTime.getTime())
        
            // Calculate the next 15-minute interval from the start time
            let currentTime = startTime;
            const minutes = new Date(currentTime).getMinutes();
            const nextQuarterHour = Math.ceil(minutes / 15) * 15;
            currentTime = new Date(currentTime).setMinutes(nextQuarterHour, 0, 0);
        
            while (currentTime <= endTime && currentTime < maxEndTime.getTime()) {
                let dt = new Date(currentTime);
                let hours = dt.getHours();
                let minutes = dt.getMinutes();
                let ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                let timeString = `${formatNum(hours)}:${formatNum(minutes)} ${ampm}`;
                slots.push({ key: timeString, value: currentTime / 1000 }); // Store time as seconds since epoch
                currentTime += interval;
            }
        
            return slots;
        };
        

        const updateSpotsList = (data) => {
            if ('error' in data) {
                alert(`Contact the Site Admin : ${data['error']}`);
            } else {
                const transformedData = data
                .filter(spot => spot.maxAvailability > 0) // Filter spots with maxAvailability > 0
                .map(spot => ({
                    key: spot.id,
                    value: `Spot ${spot.name} ${minutesToHHMM(spot.maxAvailability)}`,
                    name: spot.name
                }));
                // const availabilityMap = data.reduce((acc, spot) => {
                //     const currentTime = new Date().getTime();
                //     const maxAvailabilityTime = new Date(Object.keys(spot.availability).reduce((max, key) => Math.max(max, key), 0) * 1000).getTime();
                //     acc[spot.id] = generateTimeSlots(currentTime, maxAvailabilityTime);
                //     return acc;
                // }, {});
                // const availabilityMap = data.reduce((acc, spot) => {
                //     acc[spot.id] = Object.entries(spot.availability).map(([time, duration]) => {
                //         let dt = new Date(time * 1000);
                //         let option = `${formatNum(dt.getHours())}:${formatNum(dt.getMinutes())}`;
                //         return option;
                //     });
                //     return acc;
                // }, {});
                const availabilityMap = data.reduce((acc, spot) => {
                    const currentTime = new Date().getTime();
                    const firstAvailabilityKey = spot.availability || 0;
                    // const maxAvailabilityTime = new Date(firstAvailabilityKey * 1000).getTime();
                    acc[spot.id] = generateTimeSlots(currentTime, firstAvailabilityKey);
                    return acc;
                }, {});
                setspotOption(transformedData);
                setspotsMaxAvailableTime(availabilityMap);
            }
        };

        fetchGet('/api/reservation/getSpots', {
            'parkingLot': parkingLot
        }, updateSpotsList)
    }
    const handleSpotChange = (e) => {
        let selectedSpotId = e.target.value;
        let selectedSpotId1 = selectedSpotId - 1;
        setSpot(selectedSpotId1);
        if (spotsMaxAvailableTime[selectedSpotId]) {
            setSpotTimeOptions(spotsMaxAvailableTime[selectedSpotId]);
        } else {
            setSpotTimeOptions([]);
        }
    };
    const checkParkingLot = () => { // fetch the information of the chosen parking lot
        let checkInTime = getInTime();

        const checkParkingLotCallback = (data) => {
            if ('error' in data) {
                alert(`Contact the Site Admin : ${data['error']}`);
            } else {
                setPrice(data['price']);
                let timeDict = {};
                let options = ['Choose your parking time...'];
                for (let time in data['avaiDict']) {
                    let dt = new Date(time * 1000);
                    let option = formatNum(dt.getHours()) + ":" + formatNum(dt.getMinutes());
                    timeDict[option] = data['avaiDict'][time];
                    if (dt.getTime() <= Date.now()) {    // skip the already past time
                        continue;
                    } else {
                        options.push(option);
                    }
                }
                setTimeOptions(options);
                setAvaiDict(timeDict);
            }
        }

        fetchGet('/api/reservation/parkingLot',
            {
                'parkingLot': parkingLot,
                'inTime': checkInTime.getTime()
            },
            checkParkingLotCallback);
    }

    const handlePayment = (event) => {  // go to the payment page
        let paymentMethod = event.target.value;
        if (paymentMethod === 'Add more payment methods...') {
            setTimeout(() => {
                history.push('/profile');
            }, 1000);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let checkInTime = getInTime();
        let scheduledLeaveTime = new Date(checkInTime.getTime() + duration * 60 * 1000);
        // Round to the nearest 15 minutes
        let minutes = scheduledLeaveTime.getMinutes();
        let remainder = minutes % 15;
        if (remainder !== 0) {
            scheduledLeaveTime.setMinutes(minutes + (15 - remainder));
        }
        // Check if all necessary parameters are defined
        if (!parkingLot) {
            console.error("parkingLot is undefined or invalid");
            setBookingPrompt(<p>Encountered an error please try agian. If error persist please contact the support.</p>);
            // Handle error (e.g., show an error message or return early)
            return;
        }
        const spotKey = spot + 1;
        const spotChosen = spotOption.find(spot => spot.key === spotKey);
        console.log(spotChosen);
        if (!spotOption || !spotChosen ) {
            console.error("spotOption or the specific spot is undefined or invalid");
            console.log((spotOption + spotOption[spot] + spotOption[spot].name) )
            setBookingPrompt(<p>Encountered an error please try agian. If error persist please contact the support.</p>);
            // Handle error (e.g., show an error message or return early)
            return;
        }
        // if (!checkInTime || !(checkInTime instanceof Date) || isNaN(checkInTime)) {
        //     console.error("checkInTime is undefined or invalid");
        //     setBookingPrompt(<p>Encountered an error please try agian. If error persist please contact the support.</p>);
        //     // Handle error (e.g., show an error message or return early)
        //     return;
        // }

        // if (!scheduledLeaveTime || !(scheduledLeaveTime instanceof Date) || isNaN(scheduledLeaveTime)) {
        //     console.error("scheduledLeaveTime is undefined or invalid");
        //     setBookingPrompt(<p>Encountered an error please try agian. If error persist please contact the support.</p>);
        //     // Handle error (e.g., show an error message or return early)
        //     return;
        // }

        if (!localStorage.getItem("username")) {
            console.error("Username is not available in localStorage");
            setBookingPrompt(<p>Encountered an error please try agian. If error persist please contact the support.</p>);
            // Handle error (e.g., show an error message or return early)
            return;
        }
        setIsSubmitting(true);  // Start submission
        const params = {
            "parkingLot": parkingLot,
            "spot": spotChosen.name,
            "checkInTime": checkInTime.toISOString(),
            "scheduledLeaveTime": scheduledLeaveTime.toISOString(), 
            "username": localStorage.getItem("username")
        }

        const submitCallback = (data) => {
            if ('id' in data) {
                setBookingPrompt(<div><p>Reservation made successfully</p><p>Total Cost of Reservation:<b> ${data.charge}</b></p><p> redirect to home page in a sec...</p></div>);
                setIsBookingSuccess(true);
                setTimeout(() => {
                    handleClose();
                }, 2500);
            } else if ('non_field_errors' in data) {
                setIsSubmitting(false);  // Start submission
                setBookingPrompt(<p>{data['non_field_errors']}</p>);
            } else if ('error' in data) {
                setIsSubmitting(false);  // Start submission
                setBookingPrompt(<p>{data['error']}</p>);
            }
        }

        fetchPost('/api/reservation/createwithspot', params, submitCallback);
    }

    useEffect(() => {
        if (!optionDidUpdate) {
            checkParkingLot();
            fetchUserInfo();
            fetchSpots();
            setOptionDidUpdate(true);
            if (props.walkIn) { // set the check-in time for the walk-in mode
                let now = new Date(Date.now());
                let minute = Math.floor(now.getMinutes() / 15) * 15;
                let checkInTime = new Date(now);
                checkInTime.setMinutes(minute);

                let checkInTimeOption = formatNum(checkInTime.getHours()) + ":" + formatNum(checkInTime.getMinutes());
                if (checkInTimeOption in avaiDict) {
                    setMx(Object.values(avaiDict)[0]);
                } else {
                    setMx(0);
                }
            }
        }
    },[optionDidUpdate, checkParkingLot, fetchUserInfo, fetchSpots, props.walkIn, avaiDict]);

    return (
        <div>
            <div className={`modal ${props.show ? 'show' : ''}`} style={{ display: props.show ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-custom" role="document">
                {!isBookingSuccess ?
                    <div className="modal-content">
                        <div className="modal-header">                       
                            <h5 className="modal-title">Park Now</h5>
                            <button type="button" className="close" onClick={handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="formGridParkingLot">Parking Lot</label>
                                    <select className="form-control" id="formGridParkingLot" defaultValue="Choose a parking lot nearby..." onChange={
                                        (event) => {
                                            setParkingLot(event.target.value)
                                        }}>
                                        <option>Morewood test 1</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="parkingspot">Parking Spot</label>
                                    
                                    <select 
                                        className="form-control" 
                                        id="parkingspot" 
                                        defaultValue="Choose your parking spot..."
                                        onChange={handleSpotChange}
                                    >
                                        <option disabled>Choose your parking spot...</option>
                                        {spotOption.map((item, index) => (
                                            <option key={item.key} value={item.key}>
                                                {item.value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="duratioon">Duration</label>
                                    <select className="form-control" id="duration" defaultValue="Choose your parking duration..."
                                        onChange={calculateDuration}>
                                        <option value="NULL">Choose your parking duration...</option>
                                        {spotTimeOptions.map((item, index) => (
                                            <option key={'reserveOption' + index} value={item.value}>{item.key}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="vehicle">Select Vehicle</label>
                                    <select className="form-control" id="vehicle" value={vehicle} 
                                    onChange={
                                        (event) => {
                                            setVehicle(event.target.value)
                                        }}>
                                        <option value={vehicle}>{vehicle}</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="paymentMethod">Payment Method</label>
                                    <select className="form-control" id="paymentMethod" onChange={handlePayment}>
                                        <option>Balance</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    {durationPrompt}
                                </div>
                            </form>
                        </div>
                        <div className="modal-body">
                        {bookingPrompt}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-custom btn-cancel" onClick={handleClose}>Cancel</button>
                            {/* <button type="" className="btn btn-custom btn-confirm" onClick={handleSubmit} disabled={!isFormComplete}>Park Now</button> */}
                            <button 
                                type="button" 
                                className="btn btn-custom btn-confirm" 
                                onClick={handleSubmit} 
                                disabled={!isFormComplete || isSubmitting}>
                                {isSubmitting ? "Submitting..." : "Park Now"}
                            </button>
                        </div>
                    </div>
                :
                    <div className="modal-content">
                        <div className="modal-header">                       
                        <h5 className="modal-title">Reservation made successfully.</h5></div>
                        <div className="modal-body">
                        {bookingPrompt}
                        </div>
                    </div>
                }                  
                </div>
            </div>
        </div>
    );
}

const formatNum = (num) => {
    if (num < 10) {
        return "0" + num;
    } else {
        return num.toString();
    }
}

export default ParkNow;
