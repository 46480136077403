import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchGet, fetchPost } from "../request";
import './parknow.css'

export function ReserveForm(props) {
    const [parkingLot, setParkingLot] = useState("Morewood test 1");
    const [dateInput, setDateInput] = useState(new Date().toISOString().split('T')[0]);
    const [timeInput, setTimeInput] = useState("08:00");
    const [scheduledLeaveTime, setScheduledLeaveTime] = useState("");
    const [timeOptions, setTimeOptions] = useState([]);
    const [avaiDict, setAvaiDict] = useState({});
    const [optionDidUpdate, setOptionDidUpdate] = useState(false);
    const [durationPrompt, setDurationPrompt] = useState(<label>Choose a duration: </label>);
    const [duration, setDuration] = useState(0);
    const [mx, setMx] = useState(0); // the max duration possible
    const [bookingPrompt, setBookingPrompt] = useState(<p></p>);
    const [price, setPrice] = useState(0)
    const [vehicle, setVehicle] = useState('My Car 1')
    const [spot, setSpot] = useState(123)
    const [payment, setPayment] = useState('Balance')
    const [isFormComplete, setIsFormComplete] = useState(false);
    const history = useHistory();
    const [checkOutTimeOptions, setCheckOutTimeOptions] = useState([]);
    const [showCheckOutTimeOptions, setshowCheckOutTimeOptions] = useState(false);
    const [timeLeft, setTimeLeft] = useState(120); // 120 seconds or 2 minutes
    const [isSubmitting, setIsSubmitting] = useState(false);
    const startHour = process.env.REACT_APP_START_HOUR;
    const endHour = process.env.REACT_APP_END_HOUR;
    const convertToEST = (date) => {
        // Create a new date instance from the given date
        const utcDate = new Date(date);
    
        // Get the current offset in minutes
        const offset = utcDate.getTimezoneOffset();
    
        // Calculate EST offset (UTC-5:00) considering daylight saving time
        const estOffset = offset + 300; // 300 minutes = 5 hours
    
        // Create a new date instance in EST timezone
        const estDate = new Date(utcDate.getTime() - estOffset * 60000);
    
        return estDate;
    };

    // const handleShow = () => {  // clear the previous state and display the modal
    //     console.log("handel show called upon")
    //     setDuration(0);
    //     setBookingPrompt(<p></p>);
    //     setDurationPrompt(<label>Choose a duration:</label>);
    //     setOptionDidUpdate(false);
    //     setTimeInput("00:00")
    // }
    const handleShow = () => {
        // Reset all relevant state variables
        setParkingLot("Morewood test 1");
        setDateInput(new Date().toISOString().split('T')[0]);
        setTimeInput("08:00"); // Reset to default time
        setScheduledLeaveTime(""); // Clear scheduled leave time
        setTimeOptions([]); // Clear time options
        setAvaiDict({}); // Clear availability dictionary
        setCheckOutTimeOptions([]); // Clear checkout time options
        setOptionDidUpdate(false); // Reset option update flag
        setDuration(0); // Reset duration
        setDurationPrompt(<label>Choose a duration: </label>); // Reset duration prompt
        setBookingPrompt(<p></p>); // Clear booking prompt
        setMx(0); // Reset max duration
        setVehicle('My Car 1'); // Reset vehicle selection
        setSpot(123); // Reset spot
        setPayment('Balance'); // Reset payment method
        setIsFormComplete(false); // Reset form completion status
        setTimeLeft(120); // Reset the timer
    };
    const generateTimeSlots = (startTime, endTime) => {
        const slots = [];
        const interval = 15 * 60 * 1000; // 15 minutes in milliseconds
    
        const maxEndTime = new Date(endTime);
        maxEndTime.setHours(process.env.REACT_APP_END_HOUR, 0, 0, 0); // Set max end time based on environment variable
        console.log('The end time is')
        console.log(maxEndTime.getTime())
        // Calculate the next 15-minute interval from the start time
        let currentTime = startTime;
        const minutes = new Date(currentTime).getMinutes();
        const nextQuarterHour = Math.ceil(minutes / 15) * 15;
        currentTime = new Date(currentTime).setMinutes(nextQuarterHour, 0, 0);
    
        while (currentTime <= endTime && currentTime < maxEndTime.getTime()) {
            let dt = new Date(currentTime);
            let hours = dt.getHours();
            let minutes = dt.getMinutes();
            let ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            let timeString = `${formatNum(hours)}:${formatNum(minutes)} ${ampm}`;
            slots.push({ key: timeString, value: currentTime / 1000 }); // Store time as seconds since epoch
            currentTime += interval;
        }
        // Special case: If the end time is 23:59, add a 12:00 AM slot
        // const endDt = new Date(endTime);
        // if (endDt.getHours() === 23 && endDt.getMinutes() === 59) {
        //     slots.push({ key: '12:00 AM', value: new Date(endDt.getTime()).getTime() / 1000 }); // Add 1 minute to get 12:00 AM
        // }
    
        return slots;
    };
    
    const formatNum = (num) => {
        return num < 10 ? `0${num}` : num;
    };
    
    const handleClose = () => {
        handleShow();
        setIsSubmitting(false);
        props.handleClose();
    }
    const setupEndTime = (event) =>{
        const unixTimestamp = event.target.value;

        // Convert the Unix timestamp from seconds to milliseconds
        const endTime = new Date(unixTimestamp * 1000);

        // Get the ISO format string
        const isoString = endTime.toISOString();
        const startTime = timeInput
        if(startTime > endTime){
            alert("CheckOut time could not be before the CheckIn Time Please select appropriate time.")
        }

        
        setScheduledLeaveTime(isoString);
    }
    const parseTime = (val) => {
        const [time, period] = val.split(' ');
        let [hours, minutes] = time.split(':').map(Number);
    
        if (period === 'PM' && hours !== 12) {
            hours += 12;
        } else if (period === 'AM' && hours === 12) {
            hours = 0;
        }
    
        return [hours, minutes];
    };
    const setupStartTime = (event) => {
        
        if( event.target.value == "NULL"){
            setDurationPrompt(<label>Set duration</label>);
        }
        else {
            const val = event.target.value;
            const [year, month, day] = dateInput.split('-').map(Number);
            const [hours, minutes] = parseTime(val);
            // Create a new Date object with the correct year, month, day, hours, and minutes
            const inTime = new Date(year, month - 1, day, hours, minutes)
            setTimeInput(inTime)
            // Get the duration from avaiDict
            const duration = avaiDict[val];
            const inTime1 =new Date(inTime.getTime() + 15 * 60000);
            // Create a new Date object for endTime by adding the duration to inTime
            const endTime1 = new Date(inTime.getTime() + duration * 60000); // 60000 ms in a minute
            let outSlotList = generateTimeSlots(inTime1,endTime1);
            setCheckOutTimeOptions(outSlotList);
            return
        }
        
    }
    const checkFormComplete = () => {
        if (parkingLot && scheduledLeaveTime  && timeInput && vehicle && payment) {
            setIsFormComplete(true);
        } else {
            setIsFormComplete(false);
        }
    };

    useEffect(() => {
        checkFormComplete();
    }, [parkingLot, scheduledLeaveTime, timeInput, vehicle, payment]);

    const handleDateChange = (event) => {
        setshowCheckOutTimeOptions(false);
        // checkParkingLot();
        setTimeInput("08:00");
        setDateInput(event.target.value);
        setOptionDidUpdate(false);
    }

    const handleTimeChange = (event) => {
        setTimeInput(event.target.value);
        
        setDuration(0);
        setDurationPrompt(<label>Choose a duration:</label>);
        setMx(avaiDict[event.target.value]);
    }
    const getInDate = () => {   // utility function to get the chosen check-in time
        let checkInTime;
        if (!props.walkIn) {    // if in reservation mode
            
            // Extract year, month, and day from the dateInput
            let dateParts = dateInput.split('-');
            let year = parseInt(dateParts[0], 10);
            let month = parseInt(dateParts[1], 10) - 1; // Months are 0-based in JavaScript
            let day = parseInt(dateParts[2], 10);
    
            // Constructing the date in UTC to avoid timezone issues
            // Constructing the date in local time zone and then converting to UTC
            checkInTime = new Date(year, month, day, 0, 0, 0, 0);
            // checkInTime.setMinutes(checkInTime.getMinutes() - checkInTime.getTimezoneOffset());
            // checkInTime = new Date(Date.UTC(year, month, day, 0,0, 0, 0));
        }  else {    // if in walk-in mode0
            let now = new Date();
            let minute = Math.floor(now.getMinutes() / 15) * 15;
    
            // Constructing the walk-in time in UTC
            checkInTime = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), minute, 0, 0));
        }
        return checkInTime;
    }
    const isToday = (ipDate) => {
        const [year, month, day] = ipDate.split('-').map(Number);
        const ipDate1 = new Date(year, month - 1, day);

        // Get today's date
        const today = new Date();
        const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

        // Adjust for time zone offset
        const timeZoneOffset = today.getTimezoneOffset() * 60 * 1000;
        const estNow = new Date(today.getTime() - timeZoneOffset);
        const estTodayDate = new Date(estNow.getFullYear(), estNow.getMonth(), estNow.getDate());

        if (ipDate1.getTime() === todayDate.getTime()) {
            return true;
        } else if (ipDate1.getTime() > todayDate.getTime()) {
            return false;
        } else {
            return false;
        }
    }
    const checkParkingLot = () => { // fetch the information of the chosen parking lot
        let checkInTime = getInDate();
        const checkParkingLotCallback = (data) => {
            if ('error' in data) {
                alert(data['error']);
            } else {
                setPrice(data['price']);
                let timeDict = {};
                let options = ['Choose your parking time...'];
                const isTodayYes = isToday(dateInput)
                for (let time in data['avaiDict']) {
                    let dt = new Date(time * 1000);
                    let hours = dt.getHours();
                    // Check if the time is within startHour and endHour
                    if (hours < startHour || hours >= endHour) {
                        continue; // Skip times outside of the specified range
                    }
                    let minutes = dt.getMinutes();
                    let ampm = hours >= 12 ? 'PM' : 'AM';
                    hours = hours % 12;
                    hours = hours ? hours : 12; // the hour '0' should be '12'
                    let option = `${formatNum(hours)}:${formatNum(minutes)} ${ampm}`;
                    // let timeString = `${formatNum(hours)}:${formatNum(minutes)} ${ampm}`;
                    // let option = formatNum(dt.getHours()) + ":" + formatNum(dt.getMinutes());
                    timeDict[option] = data['avaiDict'][time];
                    if (isTodayYes && dt.getTime() <= Date.now()) {    // skip the already past time 
                        continue;
                    } else {
                        options.push(option);
                    }
                }
                setTimeOptions(options);
                setshowCheckOutTimeOptions(true)
                setAvaiDict(timeDict);
            }
        }

        fetchGet('/api/reservation/parkingLot',
            {
                'parkingLot': parkingLot,
                'inTime': checkInTime.getTime()
            },
            checkParkingLotCallback);
    }

    const handlePayment = (event) => {  // go to the payment page
        let paymentMethod = event.target.value;
        if (paymentMethod === 'Add more payment methods...') {
            setTimeout(() => {
                history.push('/profile');
            }, 1000);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmitting(true);  // Start submission
        let nnt = timeInput.toISOString();
        let mmt = timeInput.toLocaleDateString();
        //let scheduledInTimesent = convertToUTC(timeInput) ;
        const params = {
            "parkingLot": parkingLot,
            "spot": "",
            "checkInTime": nnt,
            "scheduledLeaveTime": scheduledLeaveTime,
            "username": localStorage.getItem("username")
        }

        const submitCallback = (data) => {
            if ('id' in data) {
                setBookingPrompt(<div>
                    <p><strong>Reservation Confirmed!</strong></p>
                    <p>Your reservation is set with automatic check-in. Do not forget to check out to earn credits or extend your stay early if needed. Keep an eye on your email for your assigned spot number.</p>
                </div>);
                setTimeout(() => {
                    // props.updateHandle();
                    handleClose();
                }, 5500);
            } else if ('non_field_errors' in data) {
                setIsSubmitting(false);
                setBookingPrompt(<p>{data['non_field_errors']}</p>);
            } else if ('error' in data) {
                setIsSubmitting(false);
                setBookingPrompt(<p>{data['error']}</p>);
            }
        }

        fetchPost('/api/reservation/create', params, submitCallback);
    }
    const updateCallback = (data) => {
        // Assuming data contains an array of vehicles
        if ('plate' in data) {
            setVehicle([data['plate']]);
        }
    }
    const fetchUserInfo = () => {
        fetchGet('/api/account/profile', {}, updateCallback)
    }
    useEffect(() => {
        if (!optionDidUpdate) {
            checkParkingLot();
            fetchUserInfo();
            setOptionDidUpdate(true);
            if (props.walkIn) { // set the check-in time for the walk-in mode
                let now = new Date(Date.now());
                let minute = Math.floor(now.getMinutes() / 15) * 15;
                let checkInTime = new Date(now);
                checkInTime.setMinutes(minute);

                let checkInTimeOption = formatNum(checkInTime.getHours()) + ":" + formatNum(checkInTime.getMinutes());
                if (checkInTimeOption in avaiDict) {
                    setMx(Object.values(avaiDict)[0]);
                } else {
                    setMx(0);
                }
            }
        }
    });
    useEffect(() => {
        if (props.show) {
            setTimeLeft(120); // Reset timer to 2 minutes
    
            const countdown = setInterval(() => {
                setTimeLeft((prev) => {
                    if (prev <= 1) {
                        clearInterval(countdown);
                        handleClose(); // Auto-close modal when time runs out
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
    
            const autoCloseTimer = setTimeout(() => {
                handleClose();
            }, 120000); // 2 minutes in milliseconds
    
            return () => {
                clearInterval(countdown);
                clearTimeout(autoCloseTimer);
            };
        }
    }, [props.show]);
    const formatTimeLeft = (timeLeft) => {
        const minutes = Math.floor(timeLeft / 60);
        const seconds = timeLeft % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };
    const today = new Date().toISOString().split('T')[0];
    return (
        <div>
            <div className={`modal ${props.show ? 'show' : ''}`} style={{ display: props.show ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-custom" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Reserve</h5>
                            <button type="button" className="close" onClick={handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="timer">Please complete your booking within <span style={{ color: '#C41230'}}>{formatTimeLeft(timeLeft)}</span>.</p>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="formGridParkingLot">Parking Lot</label>
                                    <select className="form-control" id="formGridParkingLot" defaultValue="Choose a parking lot nearby..." onChange={
                                        (event) => {
                                            setParkingLot(event.target.value)
                                        }}>
                                        <option>Morewood test 1</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="date">Reservation Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="date"
                                        value={dateInput}
                                        onChange={handleDateChange}
                                        min={today}
                                    />
                                </div>
                                { showCheckOutTimeOptions ?  <div className="form-group">
                                    <label htmlFor="duratioon">Start Time</label>
                                    <select className="form-control" id="duration" defaultValue="Choose your parking duration..."
                                        onChange={setupStartTime}>
                                        <React.Fragment>{timeOptions.map((item, index) => {
                                            return <option key={'reserveOption' + index}>{item}</option>
                                        })
                                        }
                                        </React.Fragment>
                                    </select>
                                </div> : <></>
                                }   
                                { timeInput == "08:00" ?<div></div>:
                                <div className="form-group">
                                    <label htmlFor="duratioon">CheckOut Time</label>
                                    <select className="form-control" id="duration" defaultValue="Choose your parking duration..."
                                        onChange={setupEndTime}>
                                        <option value="Choose your parking duration..." disabled>Choose your parking duration...</option>
                                        {checkOutTimeOptions.map((item, index) => (
                                            <option key={'reserveOption' + index} value={item.value}>{item.key}</option>
                                        ))}
                                    </select>
                                </div>
                                }
                                <div className="form-group">
                                    <label htmlFor="vehicle">Select Vehicle</label>
                                    <select className="form-control" id="vehicle" value={vehicle} 
                                    onChange={
                                        (event) => {
                                            setVehicle(event.target.value)
                                        }}>
                                        <option value={vehicle}>{vehicle}</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="paymentMethod">Payment Method</label>
                                    <select className="form-control" id="paymentMethod" onChange={handlePayment}>
                                        <option>Balance</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-custom btn-cancel" onClick={handleClose}>Cancel</button>
                            <button 
                                type="submit" 
                                className="btn btn-custom btn-confirm" 
                                onClick={handleSubmit} 
                                disabled={!isFormComplete || isSubmitting}>
                                {isSubmitting ? "Submitting..." : "Reserve"}
                            </button>
                        </div>
                        {bookingPrompt}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ReserveForm;
