import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TextField, Button, Grid, Typography, Container, Link } from '@mui/material';
import ParkingLogo from '../../images/ParkingLogo.png';
//tmpcmt
// Import the environment variable
const isProd = process.env.REACT_APP_PROD === 'true';

// Set the host based on the environment
const httpval = isProd ? 'https://' : 'http://';
export function SignIn1(props) {
    const [username, setUsername] = useState('');
    const [pwd, setPwd] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const history = useHistory();

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            'username': username,
            'password': pwd,
        };

        fetch(httpval + localStorage.getItem('host') + '/api/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                if ('access' in data) { // set JWT tokens if successfully logged in
                    localStorage.setItem('username', username);
                    localStorage.setItem('refresh', data['refresh']);
                    localStorage.setItem('access', data['access']);

                    // Fetch user profile after successful login
                    fetch(httpval + localStorage.getItem('host') + '/api/account/profile', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'JWT ' + data['access']
                        }
                    })
                        .then(response => response.json())
                        .then(profileData => {
                            localStorage.setItem('userRole', profileData.role); // Store the userRole in localStorage
                            console.log(profileData.role)
                            // Use profileData.role directly for navigation
                            if (profileData.role === 0) {
                                history.push('activehome');
                            } else if (profileData.role === 1) {
                                history.push('managerreport');
                            } else {
                                history.push('/');
                            }
                        })
                        .catch((e) => {
                            console.log("encounter an error")
                            console.log(e)
                            setUsernameError('An error occurred while fetching the profile.');
                            setPasswordError('An error occurred while fetching the profile.');
                        });
                } else {
                    setUsernameError('Incorrect username or password.');
                    setPasswordError('Incorrect username or password.');
                }
            })
            .catch(() => {
                setUsernameError('An error occurred. Please try again.');
                setPasswordError('An error occurred. Please try again.');
            });
    };

    const handleSignUp = () => {
        history.push('signup');
    };

    const handleForgotUsername = () => {
        history.push('forgotusername');
    };

    return (
        <Container 
            component="main" 
            maxWidth={false} 
            sx={{ 
                height: '100vh', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                padding: '0'
            }}
        >
            <Grid 
                container 
                spacing={3} 
                sx={{ 
                    flex: 1, 
                    height: '100%' 
                }}
            >
                <Grid 
                    item 
                    xs={12} 
                    md={5}  
                    sx={{ 
                        backgroundColor: '#C41230', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'center',
                        padding: '1rem'
                    }}
                >
                    <Typography variant="h4" align="center" sx={{ color: 'white', fontWeight: 'bold' , marginBottom: '1rem' }}>
                        Reserve Your Parking Space Today! 
                    </Typography>
                    <Typography variant="h5" align="center" sx={{ color: 'white' , fontWeight: 'bold', marginBottom: '4rem'}}> 
                        <Link href={'https://parking-pilot.cmu.edu/faq'} sx={{ color: 'white', textDecoration: 'underline', marginLeft: '4px' }}>
                            FAQ                        
                        </Link>
                    </Typography>
                </Grid>

                <Grid 
                    item 
                    xs={12} 
                    md={7} 
                    sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center' 
                    }}
                >
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2} sx={{ padding: '1rem' }}>
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                <img src={ParkingLogo} alt='Parking Logo' style={{ width: '7%', height: 'auto', maxWidth: '100%' }} />
                                <Typography variant="h5" align="center" gutterBottom sx={{ color: 'black', fontWeight: 'bold', marginTop: '1rem' }}>
                                    Tartan Parking
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="body1">
                                            Username
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            id="inputUsername"
                                            value={username}
                                            onChange={e => setUsername(e.target.value)}
                                            error={!!usernameError}
                                            helperText={usernameError}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="body1">
                                            Password
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            type="password"
                                            id="inputPassword"
                                            value={pwd}
                                            onChange={e => setPwd(e.target.value)}
                                            error={!!passwordError}
                                            helperText={passwordError}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    variant="body2"
                                    align="center"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={handleForgotUsername}
                                >
                                    Forgot Username or Password?
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    type="submit"
                                    sx={{ backgroundColor: '#C41230', color: 'white', '&:hover': { backgroundColor: '#a01028' } }}
                                >
                                    Sign In
                                </Button>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    variant="body2"
                                    align="center"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={handleSignUp}
                                >
                                    Not Registered Yet? Sign Up
                                </Typography>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Container>
    );
}

// import React, { useState } from 'react';
// import { useHistory } from 'react-router-dom';
// import { 
//   TextField, 
//   Button, 
//   Grid, 
//   Typography, 
//   Container, 
//   Link 
// } from '@mui/material';
// import ParkingLogo from '../../images/ParkingLogo.png';
// import CMULogo from '../../images/CMULogo.png';

// export function SignIn1(props) {
//     const [username, setUsername] = useState('');
//     const [pwd, setPwd] = useState('');
//     const [usernameError, setUsernameError] = useState('');
//     const [passwordError, setPasswordError] = useState('');
//     const history = useHistory();

//     const handleSubmit = (event) => {
//         event.preventDefault();

//         const data = {
//             'username': username,
//             'password': pwd,
//         };

//         fetch('http://' + localStorage.getItem('host') + '/api/token', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(data),
//         })
//             .then(response => response.json())
//             .then(data => {
//                 if ('access' in data) { // set JWT tokens if successfully logged in
//                     localStorage.setItem('username', username);
//                     localStorage.setItem('refresh', data['refresh']);
//                     localStorage.setItem('access', data['access']);

//                     history.push('activehome');
//                 } else {
//                     setUsernameError('Incorrect username or password.');
//                     setPasswordError('Incorrect username or password.');
//                 }
//             })
//             .catch(() => {
//                 setUsernameError('An error occurred. Please try again.');
//                 setPasswordError('An error occurred. Please try again.');
//             });
//     };

//     const handleSignUp = () => {
//         history.push('signup');
//     };

//     const handleForgotUsername = () => {
//         history.push('forgotusername');
//     };

//     return (
//         <Container 
//             component="main" 
//             maxWidth={false} 
//             sx={{ 
//                 height: '100vh', 
//                 display: 'flex', 
//                 alignItems: 'center', 
//                 justifyContent: 'center',
//                 padding: '0'
//             }}
//         >
//             <Grid 
//                 container 
//                 spacing={3} 
//                 sx={{ 
//                     flex: 1, 
//                     height: '100%' 
//                 }}
//             >
//                 <Grid 
//                     item 
//                     xs={12} 
//                     md={5}  
//                     sx={{ 
//                         backgroundColor: '#C41230', 
//                         display: 'flex', 
//                         flexDirection: 'column', 
//                         justifyContent: 'center',
//                         padding: '1rem'
//                     }}
//                 >
//                     <Typography variant="h4" align="center" sx={{ color: 'white', fontWeight: 'bold', marginBottom: '4rem' }}>
//                         Reserve Your Parking Space Today!
//                     </Typography>
//                     {/* <Typography variant="h6" align="center" sx={{ color: 'white', fontWeight: 'bold' }}>
//                         CMU Parking
//                     </Typography>
//                     <Typography variant="body1" align="center" sx={{ color: 'white' }}>
//                         We are a team of CMU researchers who conduct research on how to improve parking efficiency
//                         and reduce parking operational cost. A non-sensor parking pilot project is being developed and
//                         tested at CMU’s Morewood Parking Lot. Be one of the first to try out this service and receive
//                         parking credit to park for free during phase one of the program. If successful, the service will be
//                         expanded to include a fee to reserve and park this fall.
//                     </Typography>
//                     <Typography variant="body1" align="center" sx={{ color: 'white' }}>
//                         Contact: Sean Qian, 
//                         <Link href="mailto:seanqian@cmu.edu" sx={{ color: 'white', textDecoration: 'underline', marginLeft: '4px' }}>
//                             seanqian@cmu.edu
//                         </Link>, 412-268-4155
//                     </Typography> */}
//                 </Grid>

//                 <Grid 
//                     item 
//                     xs={12} 
//                     md={7} 
//                     sx={{ 
//                         display: 'flex', 
//                         alignItems: 'center', 
//                         justifyContent: 'center' 
//                     }}
//                 >
//                     <form onSubmit={handleSubmit}>
//                         <Grid container spacing={2} sx={{ padding: '1rem' }}>
//                             <Grid item xs={12} sx={{ textAlign: 'center' }}>
//                                 <img src={ParkingLogo} alt='Parking Logo' style={{ width: '7%', height: 'auto', maxWidth: '100%' }} />
//                                 <Typography variant="h5" align="center" gutterBottom sx={{ color: 'black', fontWeight: 'bold', marginTop: '1rem' }}>
//                                     Tartan Parking
//                                 </Typography>
//                             </Grid>

//                             <Grid item xs={12}>
//                                 <Grid container alignItems="center">
//                                     <Grid item xs={12} md={3}>
//                                         <Typography variant="body1">
//                                             Username
//                                         </Typography>
//                                     </Grid>
//                                     <Grid item xs={12} md={9}>
//                                         <TextField
//                                             variant="outlined"
//                                             fullWidth
//                                             id="inputUsername"
//                                             value={username}
//                                             onChange={e => setUsername(e.target.value)}
//                                             error={!!usernameError}
//                                             helperText={usernameError}
//                                         />
//                                     </Grid>
//                                 </Grid>
//                             </Grid>

//                             <Grid item xs={12}>
//                                 <Grid container alignItems="center">
//                                     <Grid item xs={12} md={3}>
//                                         <Typography variant="body1">
//                                             Password
//                                         </Typography>
//                                     </Grid>
//                                     <Grid item xs={12} md={9}>
//                                         <TextField
//                                             variant="outlined"
//                                             fullWidth
//                                             type="password"
//                                             id="inputPassword"
//                                             value={pwd}
//                                             onChange={e => setPwd(e.target.value)}
//                                             error={!!passwordError}
//                                             helperText={passwordError}
//                                         />
//                                     </Grid>
//                                 </Grid>
//                             </Grid>

//                             <Grid item xs={12}>
//                                 <Typography
//                                     variant="body2"
//                                     align="center"
//                                     sx={{ cursor: 'pointer' }}
//                                     onClick={handleForgotUsername}
//                                 >
//                                     Forgot Username or Password?
//                                 </Typography>
//                             </Grid>

//                             <Grid item xs={12}>
//                                 <Button
//                                     variant="contained"
//                                     fullWidth
//                                     type="submit"
//                                     sx={{ backgroundColor: '#C41230', color: 'white', '&:hover': { backgroundColor: '#a01028' } }}
//                                 >
//                                     Sign In
//                                 </Button>
//                             </Grid>

//                             <Grid item xs={12}>
//                                 <Typography
//                                     variant="body2"
//                                     align="center"
//                                     sx={{ cursor: 'pointer' }}
//                                     onClick={handleSignUp}
//                                 >
//                                     Not Registered Yet? Sign Up
//                                 </Typography>
//                             </Grid>
//                         </Grid>
//                     </form>
//                 </Grid>
//             </Grid>
//         </Container>
//     );
// }
