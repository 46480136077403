import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { SignIn } from "./components/SignInUp/signIn";
import { SignUp } from "./components/SignInUp/signUp";
import { MyReservations } from "./components/home";
import { PaymentPage } from "./components/payment";
// import { ProfilePage } from "./components/profile";
import ProfilePage1 from "./components/profile1";
import { Report } from "./components/Report/report";
import { ActiveHome } from './components/HomePage/ActiveHome';
import { ForgetPassword } from './components/SignInUp/ForgotUsername';
import { Notifications } from './components/Notifications';
import { UpcomingHome } from './components/HomePage/UpcomingHome';
import { HistoryHome } from './components/HomePage/HistoryHome';
import { ReportHistory } from './components/Report/ReportHistory';
import { FAQ } from './components/faq';
import { SignUp1 } from "../src/components/SignInUp/signUp1";
import { SignIn1 } from './components/SignInUp/signIn1';
import { ReportManager } from './components/Report/managerreport';
import {GenerateCouponPage} from './components/generateCoupan';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ManagerRoutes, CustomerRoutes} from './components/AuthRoute'; // Import the AuthRoute component
// Import the environment variable
const isProd = process.env.REACT_APP_PROD === 'true';

// Set the host based on the environment
const host = isProd ? 'parking-pilot.cmu.edu' : 'localhost:8000';

// Store it in localStorage
localStorage.setItem('host', host);

// Dynamically add Bootstrap Icons stylesheet
const bootstrapIcons = document.createElement("link");
bootstrapIcons.rel = "stylesheet";
bootstrapIcons.href = "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.5.0/font/bootstrap-icons.min.css";
document.head.appendChild(bootstrapIcons);
// localStorage.setItem('host', '18.207.98.215:8000')    // set the addr of the backend host
localStorage.setItem('host', host)    // set the addr of the backend host
// localStorage.setItem('host', '127.0.0.1:8000')    // set the addr of the backend host
// localStorage.setItem('host', '0.0.0.0:8000')    // set the addr of the backend host
// localStorage.setItem('host', '3.19.134.196:8000');    // set the addr of the backend host
//localStorage.setItem('host', 'localhost:8000')    // set the addr of the backend host
// localStorage.setItem('host', '127.0.0.1:8000')    // set the addr of the backend host
// localStorage.setItem('host', '0.0.0.0:8000')    // set the addr of the backend host
//localStorage.setItem('host', '3.19.134.196:8000');    // set the addr of the backend host

// localStorage.setItem('host', '');

// Store user role in localStorage for demonstration purposes
// In a real application, you should retrieve this from your authentication process
// localStorage.setItem('userRole', '0'); // Set this value according to the authenticated user's role

// Display corresponding components based on the path
ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path={"/signup"} component={SignUp1} />
      <CustomerRoutes exact path={"/payment"} component={PaymentPage} />
      <CustomerRoutes exact path={"/profile"} component={ProfilePage1} />
      <CustomerRoutes exact path={"/report"} component={Report} />
      <ManagerRoutes exact path={"/managerreport"} component={ReportManager} /> {/* Protected Route */}
      <ManagerRoutes exact path={"/generatecoupan"} component={GenerateCouponPage} /> {/* Protected Route */}
      <CustomerRoutes exact path={"/activehome"} component={ActiveHome} />
      <Route exact path={'/forgotusername'} component={ForgetPassword} />
      <CustomerRoutes exact path={'/notifications'} component={Notifications} />
      <CustomerRoutes exact path={'/upcominghome'} component={UpcomingHome} />
      <CustomerRoutes exact path={'/historyhome'} component={HistoryHome} />
      <CustomerRoutes exact path={'/reporthistory'} component={ReportHistory} />
      <Route exact path={'/faq'} component={FAQ} />
      <Route path={"/"} component={SignIn1} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import {BrowserRouter, Route, Switch} from "react-router-dom";
// import {SignIn} from "./components/SignInUp/signIn";
// import {SignUp} from "./components/SignInUp/signUp";
// import {MyReservations} from "./components/home";
// import {PaymentPage} from "./components/payment";
// // import {ProfilePage} from "./components/profile";
// import ProfilePage1 from "./components/profile1";
// import {Report} from "./components/Report/report";
// import { ActiveHome } from './components/HomePage/ActiveHome';
// import { ForgotUsername } from './components/SignInUp/ForgotUsername';
// import { Notifications } from './components/Notifications';
// import { UpcomingHome } from './components/HomePage/UpcomingHome';
// import { HistoryHome } from './components/HomePage/HistoryHome';
// import { ReportHistory } from './components/Report/ReportHistory';
// import {FAQ} from './components/faq';
// import {SignUp1} from "../src/components/SignInUp/signUp1"
// import { SignIn1 } from './components/SignInUp/signIn1';
// import {ReportManager} from './components/Report/managerreport';
// import 'bootstrap/dist/css/bootstrap.min.css';


// // localStorage.setItem('host', '18.207.98.215:8000')    // set the addr of the backend host
// // localStorage.setItem('host', 'localhost:8000')    // set the addr of the backend host
// // localStorage.setItem('host', '127.0.0.1:8000')    // set the addr of the backend host
// // localStorage.setItem('host', '0.0.0.0:8000')    // set the addr of the backend host
// localStorage.setItem('host', 'localhost:8000')    // set the addr of the backend host


// // display corresponding components based on the path
// ReactDOM.render(
//     <BrowserRouter>
//         <Switch>
//             <Route exact path={"/signup"} component={SignUp1}/>
//             <Route exact path={"/payment"} component={PaymentPage} />
//             <Route exact path={"/profile"} component={ProfilePage1} />
//             <Route exact path={"/report"} component={Report} />
//             <Route exact path={"/managerreport"} component={ReportManager} />
//             <Route exact path={"/activehome"} component={ActiveHome} />
//             <Route exact path={'/forgotusername'} component={ForgotUsername} />
//             <Route exact path={'/notifications'} component={Notifications} />
//             <Route exact path={'/upcominghome'} component={UpcomingHome} />
//             <Route exact path={'/historyhome'} component={HistoryHome} />
//             <Route exact path={'/reporthistory'} component={ReportHistory} />
//             <Route exact path={'/faq'} component={FAQ} />
//             <Route path={"/"} component={SignIn1}/>
//       </Switch>
//     </BrowserRouter>,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
